:root {
	--primary-red: #f2636f;
	--primary-pink: #f288b9;
	--primary-purple: #a080bf;
	--primary-yellow: #f2b544;
	--primary-green: #158a88;
	--primary-orange: #f28d52;
	--primary-white: #f2ead0;
	--primary-blue: #a7d9d9;

	--base-color: #19284c;
}

body,
html {
	/* background-color: black; */
	/* font-family: josefin-sans, sans-serif !important; */
	font-weight: 300;
	font-style: normal;
	overflow-x: hidden;
	/* color: var(--base-color); */
}

/* TEXT COLORS */
.text-pink {
	color: var(--primary-pink) !important;
}

.text-purple {
	color: var(--primary-purple) !important;
}

.text-yellow {
	color: var(--primary-yellow) !important;
}

.text-green {
	color: var(--primary-green) !important;
}

.text-red {
	color: var(--primary-red) !important;
}

.text-orange {
	color: var(--primary-orange) !important;
}

.text-blue {
	color: var(--primary-blue) !important;
}
.text-whitesmoke {
	color: whitesmoke !important;
}

/* BACKGROUND COLORS */

.bg-primary-white {
	background-color: var(--primary-white) !important;
}
.bg-primary-yellow {
	background-color: var(--primary-yellow) !important;
}
.bg-primary-red {
	background-color: var(--primary-red) !important;
}
.bg-primary-green {
	background-color: var(--primary-green) !important;
}

.bg-primary-blue {
	background-color: var(--primary-blue) !important;
}
.bg-bright-yellow {
	background-color: var(--bright-yellow) !important;
}

/* ----------------VIDEO---------------- */
.player-wrapper {
	width: auto;
	height: auto;
}
.react-player {
	padding-top: 56.25%;
	position: relative;
}

.react-player > div {
	position: absolute;
}

/* NAV TEXT COLOR */
.nav.navbar-nav.navbar-right li a {
	color: white !important;
}

.navBar {
	z-index: 999;
}

/* BACKGROUND PARALLAX */
.bg-para {
	min-height: '100%';
	position: relative;
}

/* .bg-para .content {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.content span.img-txt {
	background-color: #333;
	text-transform: uppercase;
	color: #fff;
	padding: 1rem;
	font-size: 1.5rem;
	letter-spacing: 10px;
} */

/* HERO BACKGROUND */

.bg-background {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.bg-miami {
	background-image: url(./assets/miamiBackground.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.bg-nyc {
	background-image: url('../src/assets/nyc.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
/* .bg-selfie {
	background-image: linear-gradient(
			rgba(0, 0, 0, 0.188),
			rgba(0, 0, 0, 0.167)
		),
		url('../src/assets/singingSelfie.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
} */

.bg-iridium {
	background-image: url('../src/assets/iridium1.jpeg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-iridium::before {
	content: '';
	background-color: var(--primary-green);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.89;
}
.bg-clubs {
	background-image: url('../src/assets/nightClubs.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-clubs::before {
	content: '';
	background-color: var(--primary-green);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.97;
}

.bg-action {
	background-image: url('../src/assets/actionShot.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-action::before {
	content: '';
	background-color: var(--primary-green);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.89;
}

.bg-tux {
	background-image: url('../src/assets/tuxPiano.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-tux::before {
	content: '';
	background-color: var(--primary-green);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.89;
}

.bg-ymca {
	background-image: url('../src/assets/ymca.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-ymca::before {
	content: '';
	background-color: var(--primary-green);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.89;
}

.bg-overlay-hero {
	background: transparent;
	background: linear-gradient(
		90deg,
		rgba(75, 75, 77, 0.327) 0%,
		rgba(71, 71, 71, 0.213) 100%
	);
}

.bg-blurry {
	backdrop-filter: blur(5px);
}

.bg-dark-overlay {
	background: rgba(black, 0.5) !important;
}

/* ------------------HOVER CARDS-------------- */

.show-wrapper .show {
	max-width: 300px;
	height: 100px;
	margin: 30px 10px;
	padding: 20px 10px;
	box-shadow: 0 1px 2px rgba(123, 122, 122, 0.405);
	transition: 0.3s ease-in-out;
}
.show-wrapper .show:hover {
	height: 400px;
}
.show-wrapper .show .imgShow-wrapper {
	position: relative;
	justify-content: center;
	text-align: center;
	width: auto;
	height: auto;
	top: -50px;
	left: 10px;
	z-index: 1;
}
.show-wrapper .show .imgShow-wrapper img {
	max-width: 100%;
	border-radius: 25px;
}
.show-wrapper .show .content {
	position: relative;
	margin-top: -140px;
	padding: 1px 15px;
	text-align: center;
	color: var(--primary-green);
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s ease-in-out;
}
.show-wrapper .show:hover .content {
	visibility: visible;
	opacity: 1;
	margin-top: 2px;
	transition-delay: 0.3s;
}

.accordion-button {
	background-color: none !important;
	color: var(--primary-green) !important;
}

.accordion-button:focus {
	box-shadow: none;
}

/* .accordion-button:hover {
	filter: drop-shadow(0.16rem 0.1rem 0.3rem #19284c);
} */

.accordion-button:not(.collapsed) {
	color: white !important;
	background-color: var(--primary-green);
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

/* .accordion-button::after {
	background-image: url(../src/assets/birdIconLarge.png);
} */

.accordion-button:not(.collapsed)::after {
	color: white;

	background-image: url(../src/assets/logo_SHORT_WHITE_upside.png);
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.7rem;
	margin-left: auto;
	content: '';
	background-image: url(../src/assets/logo_SHORT_GREEN.png);
	background-repeat: no-repeat;
	background-size: 2rem;
	transition: transform 0.2s ease-in-out;
}
