.hero-container > video {
	object-fit: cover;
	width: 100%;
	height: 700px;
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
}

.hero-container {
	height: 650px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.243);
	object-fit: contain;
}

/* .hero-container > p {
	margin-top: 8px;
	color: #fff;
	font-size: 100px;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
		'Lucida Sans', Arial, sans-serif;
	z-index: 0;
} */
